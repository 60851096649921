import React from "react"
import { Link } from "react-router-dom"

export function UserNotLogin({ isUserLogin }) {
	return (
		<div
			className={`fixed w-full h-full top-0 left-0 bg-popup1-custom z-50  ${
				isUserLogin === "" ? "flex" : "hidden"
			} flex-col justify-center items-center`}>
			<h3 className="text-2xl font-black">Musisz się zalogować</h3>
			<ul className="text-center">
				<li>Aby móc skorzystać z tej funkcji musisz byc zalogowany.</li>
				<li>Założenie konta jest bezpłatne</li>
			</ul>

			<Link to="/login" className="my-1 bg-orange-custom py-1 px-2 rounded-lg mt-4">
				Zaloguj się
			</Link>
		</div>
	)
}
